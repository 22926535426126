import React, {useEffect, useState, createContext, useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useOktaAuth} from '@okta/okta-react';
import {useLocation, useHistory} from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';
import {AccountStatus, setOktaAuthState, setOktaAuth, logout, oktaLoginUser, setUserId, setHideInitialSpinner, setUseOktaAuthentication} from './../store/Network';
import {connectToWebsocket} from './../store/socket';
import {getSettings, getFacilities, saveUserReduxState, initializeUser, getLists} from './../store/User';
import {loadModalities} from './../store/System';
import {loadUserLegacyCart} from './../store/Cart';
import {getCookie, logEvent, translateHomeScreen} from 'utility';
import {oktaSignInConfig} from '../oktaConfig';
import Spinner from 'components/Spinner';

export const AuthHandlerContext = createContext({});

const OKTA_OAUTH2_NAME = process.env.REACT_APP_OAUTH2_NAME;

export const AuthHandlerProvider = ({children}) => {
    const {oktaAuth, authState} = useOktaAuth();
    
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const dashboard = useSelector(state => state.system.configuration.features.dashboard);
    const notifications = useSelector(state => state.system.configuration.features.notifications);
    const subscriptionId = useSelector(state => state.network.tokenInfo.subscriptionId);
    const isImpersonating = useSelector(state => state.network.isImpersonating);
    const isLoggingIn = useSelector(state => state.network.isLoggingIn || false);
    const isLoggingOut = useSelector(state => state.network.isLoggingOut || false);
    const sessionStorageAvailable = useSelector(state => state.network.sessionStorageAvailable);
    const hideInitialSpinner = useSelector(state => state.network.hideInitialSpinner);    
    const oktaAuthentication = useSelector(state => state.system.siteSettings.oktaAuthentication);

    const [showSpinner, setShowSpinner] = useState(!hideInitialSpinner);
    //const [oktaAuthentication, setOktaAuthentication] = useState(true);
    const [userLoaded, setUserLoaded] = useState(false);
    const [logoutTriggered, setLogoutTriggered] = useState(false);

    useEffect(() => {
        dispatch(setOktaAuth(oktaAuth));
    }, [oktaAuth]);

    useEffect(() => {
        //const {response: {data: {oktaAuthentication: auth}}} = x;
        //setOktaAuthentication(auth);
        dispatch(setUseOktaAuthentication(oktaAuthentication));  
    }, [])

    useEffect(() => {   
        // Logic controlling the main spinner

        if (location.pathname === '/login' && !authState?.accessToken && oktaAuthentication) {
            // OktaLogin.jsx has it's own spinner
            setShowSpinner(false);
            return;
        }

        const isImpersonating = Cookies.get('impersonate') != null;

        const pendingUserInfo = 
            authState != null && authState.isAuthenticated && oktaAuthentication
            && !userLoaded 
            && (!authState.accessToken.claims.isEmployee || (!isImpersonating && location.pathname.indexOf('/admin') !== 0));
        
        const show = (isLoggingIn || isLoggingOut || logoutTriggered || (!authState && !hideInitialSpinner) || pendingUserInfo);        
        setShowSpinner(show);
               
    }, [isLoggingIn, isLoggingOut, authState, userLoaded, location.pathname])

    useEffect(() => {
        if (!authState || !oktaAuth) return;

        if (authState && hideInitialSpinner) {
            dispatch(setHideInitialSpinner(false));
        }

        let impersonating = Cookies.get('impersonate') != null;

        if (!authState.isAuthenticated && !impersonating && Cookies.get('access_token') != null) {
            checkSSO()
        }

        if (authState.isAuthenticated) {
            // Expired SSO Session
            if (!authState.accessToken.claims.authenticationKey && Cookies.get('token') == null && Cookies.get('impersonate') == null) {
                oktaAuth.tokenManager.clear();
            } else if (Cookies.get('impersonate') == null) {
                setOktaAuthState(authState);                
                login().then(() => setUserLoaded(true));
            }
        }
    }, [authState, oktaAuth])    

    useEffect(() => {
        if (location.pathname === '/logout' && oktaAuth) {
            logUserOut();
        }
    }, [oktaAuth, location.pathname]);

    const login = async () => {      
        const ssoLogin = Cookies.get('ssoLogin') != null;
        const {accessToken, idToken, refreshToken} = authState;

        const info = await oktaAuth.token.getUserInfo(accessToken, idToken);
        
        if (!ssoLogin)
            await dispatch(oktaLoginUser(info, accessToken, refreshToken));

        await dispatch(initializeUser());
        // await dispatch(resetPath());

        if (sessionStorageAvailable)
            sessionStorage.removeItem('fields');

        Cookies.remove('browsingHistory');
        const {ak1, ak2, apolloAdminRole, firstName, lastName} = accessToken.claims;
        const status = parseInt(accessToken.claims.status);
        const userId = parseInt(accessToken.claims.userId);
        const rootCompanyId = parseInt(accessToken.claims.rootCompanyId);
        const isEmployee = accessToken.claims.isEmployee;
        const impersonating = accessToken.claims.isImpersonating;

        if (status) {
            if (window.appInsights) {
                window.appInsights.setAuthenticatedUserContext(userId.toString(), rootCompanyId.toString(), true);
                window.appInsights.context.user.id = userId.toString();
            }
        }
        
        if (isEmployee) {
            if (location.pathname.indexOf('/admin') === 0) return;
            // dispatch(setIsLoggingIn(false));
            if (apolloAdminRole === '4')
                finishLoading('', null, null, '/admin/rfq');
            else
                finishLoading('', null, null, '/admin/company');
       
            return;
        }

        dispatch(getLists());
        dispatch(loadModalities())

        // TODO: If the user needs to accept the EULA or change password,
        // none of the other things in the login flow will have happened.
        // (no settings loaded)
        if (status & AccountStatus.Valid) {
            const settings = await dispatch(getSettings());
            const user = settings.response.data.settings;
            const {url} = settings;
            const {cookiePolicyAccepted, eulaRequired} = settings.response.data.userInfo;
            
            dispatch(setUserId(userId, firstName, lastName, subscriptionId, false, eulaRequired));

            if (dashboard && notifications && user.hasDashboardv2Access) {
                dispatch(connectToWebsocket());
            }

            if (user.hasReportsAccess)
                axios.post('/AuthenticationService/api/v1.0/sisense/initiate');

            dispatch(loadUserLegacyCart());
            const facilitiesResponse = await dispatch(getFacilities());
            if (facilitiesResponse.response && facilitiesResponse.response.data) {
                let defaultFacility = _.find(facilitiesResponse.response.data, f => _.toNumber(f.facilityId) === _.toNumber(user.defaultFacilityId));

                if (!defaultFacility && facilitiesResponse.response.data.length === 1)
                    defaultFacility = facilitiesResponse.response.data[0];

                if (defaultFacility) {
                    dispatch(saveUserReduxState({facility: defaultFacility}));
                }

                if (isImpersonating) {
                    finishLoading('', ak1, ak2, '/');
                }
                // After user is loaded, if privacy policy has changed, ask the user to either accept it ot
                if (eulaRequired && !impersonating) {                    
                    finishLoading(url, ak1, ak2, '/policies-acceptance');
                    return;
                }

                if (window.location.pathname.toLowerCase().indexOf('/login') !== 0 && 
                    window.location.pathname !== '/') {
                    return;
                } else if (!impersonating && sessionStorageAvailable && sessionStorage.prevUrl && sessionStorage.prevUrl !== '/' && sessionStorage.prevUrl !== '/login' && sessionStorage.prevUrl !== '/changepassword') {
                    finishLoading('', null, null, sessionStorage.prevUrl);
                } else {
                    finishLoading(url, ak1, ak2, translateHomeScreen(user.defaultHomeScreen, user.hasDashboardv2Access));
                    trackLogin(status);
                    return;
                }
            }
        } 
        
        finishLoading('', null, null, '/');
    };

    const checkSSO = () => {     
        let access_token = Cookies.get('access_token');
        let id_token = Cookies.get('id_token');
        let refresh_token = Cookies.get('refresh_token');
        let date = Cookies.get('expires_at');
        let expires_at = Date.parse(date);
        let scope = Cookies.get('scope');
        let token_type = Cookies.get('token_type');
        let token = Cookies.get('token');
        let tokenExpiration = Cookies.get('tokenExpiration')

        if (access_token && id_token && refresh_token && expires_at && scope && token && tokenExpiration) {           
            let oToken = {expiresAt: expires_at, scope: scope.split(' ')};

            let accessToken = Object.assign({},oToken);
            let decodedAccess = oktaAuth.token.decode(access_token);
            accessToken.claims = decodedAccess.payload;
            accessToken.accessToken = access_token;
            accessToken.tokenType = token_type;
            accessToken.userinfoUrl = `${oktaSignInConfig.baseUrl}/oauth2/${OKTA_OAUTH2_NAME}/v1/userinfo`;

            let idToken = Object.assign({},oToken);
            let decodedId = oktaAuth.token.decode(id_token);
            idToken.claims = decodedId.payload;
            idToken.idToken = id_token;
            
            let refreshToken = Object.assign({},oToken);
            refreshToken.refreshToken = refresh_token;

            let tokens = {'accessToken': accessToken,
                'idToken': idToken,
                'refreshToken': refreshToken};

            oktaAuth.tokenManager.setTokens(tokens);
            removeSSOTokens();

            return true;
        }

        return false;
    }

    const triggerLogout = useCallback((response) => {
        setLogoutTriggered(true);
        oktaAuth.tokenManager.clear();
        dispatch(logout());

        // Remove Okta SSO cookies
        removeSSOTokens();

        Cookies.remove('subscriptionId');
        Cookies.remove('impersonate');
        Cookies.remove('token');
        Cookies.remove('ssoLogin');

        setUserLoaded(false);
    }, []);

    const removeSSOTokens = () => {
        Cookies.remove('access_token');
        Cookies.remove('id_token');
        Cookies.remove('refresh_token');
        Cookies.remove('expires_at');
        Cookies.remove('scope');
        Cookies.remove('token_type');
    }
      
    const contextValue = useMemo(() => ({
        oktaAuthentication,
        triggerLogout,
    }), [oktaAuthentication, triggerLogout]);

    const getCookieAcceptanceValue = () => {
        const cookieName = 'cookie-policy';

        if (getCookie(cookieName))
            return true;
        else
            return false;
    };

    const trackLogin = (status) => {
        logEvent('LOGIN', {
            'Login': status === 200 ? 'true' : 'false',
            'redirect': location.href,
        });
    };

    const finishLoading = (url, ak1, ak2, redUrl) => {            
        if (isImpersonating || redUrl === '/checkout') {
            history.push('/');
        } else if (redUrl && redUrl !== '/') {
            redirect(url, ak1, ak2, redUrl);
        }
    };

    const redirect = (url, ak1, ak2, pathname) => {
        if (url !== '' && window.location.hostname !== 'localhost' && window.location.href.indexOf('partssource.com') === -1)
            window.location.replace(url + pathname + '?ak1=' + ak1 + '&ak2=' + ak2);
        else
            history.push(pathname)

        return;
    };

    const logUserOut = () => {
        oktaAuth.tokenManager.clear();
        oktaAuth.signOut().then(() => dispatch(logout()));
    }

    return (
        <AuthHandlerContext.Provider value={contextValue}>
            {showSpinner ? <Spinner /> : children}
        </AuthHandlerContext.Provider>
    );
};
